export const loggly_payment_type = {
  ADDON: 'ADDON BOUGHT',
  ADC_RECHARGE: 'ADC RECHARGE',
  CREDITS: 'CREDITS BOUGHT',
  CREATE_CANDIDATE: 'CANDIDATE ADDED',
  PACKAGE_BOUGHT: 'PACKAGE BOUGHT',
  ORDER: 'ORDER'
};

export const IdentityArr = {
  PAN: 'PAN',
  DL: 'Driving License',
  VOTER_ID: 'Voter ID',
  AADHAAR: 'Aadhaar',
  PASSPORT: 'Passport'
};

export const AddressArr = {
  CURRENT: 'Current address',
  PERMANENT: 'Permanent address',
  ANY_1: 'Any 1 (PERMANENT,CURRENT)',
  ANY_2: 'PERMANENT,CURRENT'
};

export const AddressConstants = {
  ANY_1: 'ANY_1',
  ANY_2: 'ANY_2',
  PERMANENT: 'PERMANENT',
  CURRENT: 'CURRENT'
};

export const EducationArr = {
  10: 'Tenth',
  12: 'Twelfth / Diploma',
  UG: 'Graduation',
  UNDERGRAD: 'Graduation',
  PG: 'Post Graduation',
  POSTGRAD: 'Post Graduation',
  PHD: 'PhD',
  PHD: 'PhD'
};
export const DRUG_CHECK_TEXT = {
  '5_PANEL': '5 Panel',
  '7_PANEL': '7 Panel',
  '10_PANEL': '10 Panel'
};
export const TDS_OPTIONS = [
  { value: 'NONE', label: 'None', disable: false },
  { value: '2', label: '2%', disable: false },
  { value: '10', label: '10%', disable: false }
];

export const PACKAGE_UNIT_INDICATION = {
  NILL: 'NILL', // CRITICAL
  LOW: 'LOW',
  SUFFICIENT: 'SUFFICIENT'
};

export const WALLET_BALLANCE_INDICATION = {
  NILL: 'NILL', // CRITICAL
  LOW: 'LOW',
  SUFFICIENT: 'SUFFICIENT'
};

export const SUPPORTED_MIME_TYPE = {
  jpg: 'image/jpeg',
  jpeg: 'image/jpeg',
  png: 'image/png',
  doc: 'application/msword',
  docx: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  pdf: 'application/pdf',
  xls: 'application/vnd.ms-excel',
  xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  csv: 'text/csv'
};
export const SUPPORTED_SIGNATURE_FAMILY = {
  image: ['jpeg', 'png', 'jpg'],
  binary: ['doc', 'xls'],
  pdf: ['pdf'],
  zip: ['docx', 'xlsx'],
  csv: ['csv']
};
export const SUPPORTED_MIME_TYPE_FAMILY = {
  image: ['image/jpeg', 'image/png'],
  document: [
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  ],
  pdf: ['application/pdf'],
  sheet: [
    'application/vnd.ms-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  ],
  csv: ['text/csv']
};
export const UPLOAD_STATE = {
  UPLOADING: 'UPLOADING',
  UPLOADED: 'UPLOADED',
  NOT_UPLOADED: 'NOT_UPLOADED',
  FILE_TOO_LARGE: 'FILE_TOO_LARGE',
  NETWORK_ERROR: 'NETWORK_ERROR',
  FILE_ERROR: 'FILE_ERROR'
};

export const ADMIN_URL = [
  'dev-admin',
  'dev-2-admin',
  'dev-3-admin',
  'dev-4-admin',
  'dev-5-admin',
  'dev-6-admin',
  'admin',
  'acceptance-admin',
  'acceptance-2-admin',
  'localhost:3001'
];
export const PACKAGE_UNIT_INDICATION_FACTOR = 0.2;
export const SAMPLE_FILE_ALUMNI =
  'https://springverify-assets-id.s3.amazonaws.com/SpringVerify%20Alumni%20Verification%20Sample.xlsx';

export const BULK_ALLOWED_FORMATS = ['xlsx', 'xls', 'csv'];
