import React from 'react';
import styles from './UnAuthorized.module.scss';

const UnAuthorized = ({ fileDeleted }) => {
  return (
    <div className={styles.unAuthorized}>
      <img
        src='https://springverify-assets-id.s3.amazonaws.com/static-content/images/springverify-icon-mail.png'
        className={styles.imgStyle}
        alt='SpringVerify Logo'
      />
      <div style={{ marginTop: '30px' }}>
        {fileDeleted ? (
          <h6 className={styles.labelStyle}>
            This file relates to a case that no longer exists on the SpringVerify portal. Please
            contact the support team.
          </h6>
        ) : (
          <h6 className={styles.labelStyle}>You cannot access this resource.</h6>
        )}
      </div>
    </div>
  );
};

export default UnAuthorized;
