import React from 'react';
import Select from 'react-select';
import { Card, Form, Col } from 'react-bootstrap';
import { Field, ErrorMessage } from 'formik';
import moment from 'moment';
import DatePicker from 'react-datepicker';
import Modal from 'react-bootstrap/Modal';
import { intersectionWith, isEmpty, toLower } from 'lodash';
import { isMobileOnly } from 'react-device-detect';
import CategoryModals from '../CategoryModals';
import AdminModals from '../Administrators/AdminModals';
import CustomValidatedSelect from '../../../core-components/CustomValidatedSelect';
import ValidatedFormInputField from '../../../core-components/ValidatedFormInputField';
import ValidatedRadioFields from '../../../core-components/ValidatedRadioFields';
import Button from '../../../core-components/Button';
import ValidatedFileSelect from '../../../core-components/ValidatedFileSelect';
import Image from '../../../core-components/Image';
import {
  getTrimmedValue,
  isTrimmedPhoneValid,
  validateCategoriesAndTags,
  isValidPhoneNumber
} from '../../../utils/utilities';
import countryNames from '../../../utils/countryNamesEn.json';
import countryOptions from '../../../utils/countryCodesEn.json';
import {
  checkCandidateEmail as checkCandidateEmailAPI,
  checkCandidatePhone as checkCandidatePhoneAPI,
  checkCandidateEmployeeId as checkCandidateEmployeeIdAPI
} from '../../../api/company';
import { capitalizeName } from '../frontend-common/utils/Utilities';
import styles from './EditCandidate.module.scss';
import { allowOnlyNumbersRegex } from '../../../utils/ValidationSchema';

export default class EditCandidate extends React.Component {
  state = {
    modalShow: false,
    categories: [],
    tags: [],
    formConfig: {},
    modalTitle: null,
    modalSubTitle: null,
    modalShowStatus: false,
    modalButton: null,
    modalPlaceholder: null,
    isAdmin: localStorage.accessLevel === 'admin',
    resumeError: false,
    isUploading: null,
    reloadingConfig: false,
    deletedCategoryId: '',
    deletedTags: [],
    deletedCatError: '',
    deletedTagsError: '',
    selectedCategory: {}
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    this.fetchCategories();
    this.fetchTags();
    this.props?.getCandidateFormConfig().then(() => {
      this.setState({
        formConfig: {
          isEmailOptional: this.props?.formConfig?.isEmailOptional, // 0 is required, 1 not required
          isResumeOptional: this.props?.formConfig?.isResumeOptional
        }
      });
    });

    if (
      this.props.values.countryCode &&
      this.props.values.phoneNumber &&
      !isValidPhoneNumber(this.props.values.countryCode + this.props.values.phoneNumber)
    ) {
      this.props.setFieldValue('isValidPhone', false);
    } else {
      this.props.setFieldValue('isValidPhone', true);
    }
  }

  fetchCategories = () => {
    let categoriesList = [];
    return this.props?.getCategories().then(() => {
      this.props?.categories &&
        this.props?.categories.userCategories &&
        Array.isArray(this.props?.categories.userCategories) &&
        (categoriesList = this.props?.categories.userCategories.map((category) => {
          return { label: category.categoryName, value: category.id };
        }));

      const selectedCategory = categoriesList.find((c) => c.value === this.props.values?.category);
      this.setState({
        categories: categoriesList,
        selectedCategory: selectedCategory
      });
    });
  };

  fetchTags = () => {
    let tagsList = [];
    return this.props?.getTags().then(() => {
      this.props?.tags &&
        this.props?.tags.categories &&
        Array.isArray(this.props.tags.categories) &&
        this.props.tags.categories.map((tag) => {
          tagsList.push({ label: tag.category, value: tag.id });
        });
      this.setState({ tags: tagsList });
    });
  };

  modalToggle = (title, subTitle, buttonText, placeholder) => {
    this.setState({
      modalShow: true,
      modalTitle: title,
      modalSubTitle: subTitle,
      modalButton: buttonText,
      modalPlaceholder: placeholder
    });
  };

  modalHide = (value) => {
    if (value === 'reload category') {
      this.setState({
        modalShowStatus: true,
        modalShow: false
      });
      this.fetchCategories();
    } else if (value === 'reload tag') {
      this.setState({
        modalShowStatus: true,
        modalShow: false
      });
      this.fetchTags();
    } else {
      this.setState({
        modalShow: false
      });
    }
  };

  modalHideStatus = () => {
    this.setState({
      modalShowStatus: false
    });
  };

  setResumeError = (value) => {
    this.setState({
      resumeError: value
    });
  };

  setIsUploading = (value) => {
    this.setState({
      isUploading: value
    });
  };

  render() {
    const {
      handleSubmit,
      isSubmitting,
      values,
      errors,
      setFieldValue,
      dirty,
      setFieldError,
      companyId,
      getCandidateConfig,
      touched
    } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        fontFamily: 'Poppins',
        fontSize: '14px',
        lineHeight: '21px',
        borderRadius: '4px',
        padding: '10px 20px',
        border: 'none',
        cursor: 'pointer',
        color: state.isSelected ? '#fff' : state.isDisabled ? '#bbb' : '#666666',
        textAlign: state.isDisabled && 'center',
        background: state.isDisabled && '#fff !important'
      }),
      control: (provided, state) => ({
        ...provided,
        width: '565px',
        border: '1px solid #ebedf2',
        borderColor: state.selectProps.error ? '#dc3545 !important' : '#ebedf2',
        boxSizing: 'border-box',
        borderRadius: '4px',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#333333',
        display: 'flex'
      }),
      placeholder: (defaultStyles) => {
        return {
          ...defaultStyles,
          color: '#333333'
        };
      },
      valueContainer: (base) => ({
        ...base
      }),
      container: (base) => ({
        ...base,
        width: '474px'
      }),
      menu: (provided, state) => ({
        ...provided,
        zIndex: '10',
        width: '565px'
      })
    };

    const IndicatorSeparator = ({ innerProps }) => {
      return <span style={{ display: 'none' }} {...innerProps} />;
    };
    const setValue = (fieldname, value, withSpace) => {
      const trimmedVal = getTrimmedValue(value, withSpace);
      setFieldValue(fieldname, trimmedVal);
      return trimmedVal;
    };

    const handleReloadCategories = async () => {
      this.setState({ reloadingConfig: true });
      await this.fetchCategories();
      this.setState({ reloadingConfig: false });
    };

    const handleReloadTags = async () => {
      this.setState({ reloadingConfig: true });
      await this.fetchTags();
      this.setState({ reloadingConfig: false });
    };

    const handleFormSubmit = async () => {
      const selectedValues = {
        category_id: values?.category || '',
        category_name: this.state.selectedCategory?.label || '',
        tags: values?.tag ? values.tag.map((t) => ({ id: t.value, category: t.label })) : []
      };
      if (
        !selectedValues.category_id &&
        (!selectedValues?.tags || selectedValues?.tags?.length === 0)
      ) {
        handleSubmit();
        return;
      }

      try {
        this.props.setSubmitting(true);
        const config = await getCandidateConfig(companyId);
        const categoriesList = config?.categories;
        const tags = config?.tags;

        const res = validateCategoriesAndTags(selectedValues, categoriesList, tags);
        if (isEmpty(res)) {
          handleSubmit();
          return;
        }
        if (res?.tagsError) {
          this.setState({
            deletedTagsError: res?.tagsError,
            deletedTags: res?.deletedTags
          });
        }
        if (res?.categoryError) {
          this.setState({
            deletedCatError: res?.categoryError,
            deletedCategoryId: res?.deletedCategoryId
          });
        }
        this.props.setSubmitting(false);
      } catch (err) {
        this.props.setSubmitting(false);
        console.error('Handle Save Error: ', err);
      }
    };

    const validateCategoryOnChange = (value) => {
      if (!value || (value && value !== this.state.deletedCategoryId)) {
        this.setState({ deletedCatError: null });
      }
    };

    const validateTagsOnChange = (value) => {
      if (!value || this.state.deletedTags.length <= 0) {
        this.setState({ deletedTagsError: null });
        return;
      }
      if (value && this.state.deletedTags.length > 0) {
        const selectedTags = value.map((t) => ({ id: t.value, category: t.label }));
        const comparator = (a, b) => a.id === b.id;
        const res = intersectionWith(selectedTags, this.state.deletedTags, comparator);
        if (res.length <= 0) this.setState({ deletedTagsError: null });
      }
    };

    const isValidForm =
      !isSubmitting &&
      isEmpty(errors) &&
      !values.phoneNumberExists &&
      values.isValidPhone &&
      values.isAltValidPhone &&
      !this.state.isUploading &&
      !this.state.deletedCatError &&
      !this.state.deletedTagsError &&
      !this.state.resumeError;

    return (
      <div className={styles.addCandidate}>
        <div className={styles.cardSection}>
          <Card>
            <Modal.Header closeButton>
              <Modal.Title id='contained-modal-title-vcenter'>{'Edit2 candidate'}</Modal.Title>
            </Modal.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Form.Label>
                  Name <span>*</span>
                </Form.Label>
                <Form.Row>
                  <Field
                    data-testid='name-field'
                    type='text'
                    name='name'
                    component={ValidatedFormInputField}
                    errors={errors.name}
                    onBlur={(e) => {
                      this.props?.handleBlur(e);
                      setValue('name', capitalizeName(values?.name), true);
                    }}
                  />
                </Form.Row>
                <Form.Label>
                  Email {!this.state.formConfig.isEmailOptional ? <span>*</span> : null}
                </Form.Label>
                <Form.Row>
                  <Field
                    type='text'
                    name='email'
                    data-testid='email-field'
                    className={values.emailExists || values.emailInvalid ? styles.emailError : null}
                    component={ValidatedFormInputField}
                    errors={errors.email}
                    onBlur={(e) => {
                      this.props?.handleBlur(e);
                      const trimmedVal = setValue('email', toLower(values?.email), false);
                      if (values?.email && !errors.email) {
                        setFieldValue('emailInvalid', '');
                        if (
                          this.props?.editTitle !== 'Edit' ||
                          (this.props?.editTitle === 'Edit' &&
                            this.props?.candidateDetails &&
                            this.props.candidateDetails?.email !== trimmedVal)
                        ) {
                          checkCandidateEmailAPI(trimmedVal)
                            .then((response) => {
                              if (response.data.data.data) {
                                setFieldValue('emailExists', true);
                              } else {
                                setFieldValue('emailExists', false);
                              }
                            })
                            .catch((error) => {
                              setFieldValue('emailInvalid', 'Invalid Email');
                            });
                        }
                      }
                    }}
                  />
                  {!errors.email && (values.emailExists || values.emailInvalid) && (
                    <div className={styles.emailErrorMsg}>
                      {values.emailExists
                        ? 'Email already exists'
                        : values.emailInvalid
                        ? values.emailInvalid
                        : ''}
                    </div>
                  )}
                </Form.Row>
                <Form.Label>
                  Phone Number <span>*</span>
                </Form.Label>
                <Form.Row>
                  {!isMobileOnly ? (
                    <Field
                      type='text'
                      name='countryCode'
                      defaultValue={values.countryCode}
                      options={countryNames}
                      className='countryCodeDropDown'
                      filterLabel={countryOptions}
                      component={CustomValidatedSelect}
                      onChange={(e) => {
                        if (values.phoneNumber && e) {
                          isTrimmedPhoneValid(e, values.phoneNumber, setFieldValue, 'isValidPhone');
                          let intlPhoneNumber = `${e + '' + values.phoneNumber}`;
                          checkCandidatePhoneAPI(intlPhoneNumber, this?.props?.candidateDetails?.id)
                            .then((response) => {
                              if (response.data.data.data !== null) {
                                setFieldValue('phoneNumberExists', true);
                              } else {
                                setFieldValue('phoneNumberExists', false);
                              }
                            })
                            .catch((error) => {
                              setFieldValue('phoneNumberExists', false);
                            });
                        }
                      }}
                      onBlur={(e) => {
                        this.props?.handleBlur(e);
                        setValue('phoneNumber', values?.phoneNumber, false);
                      }}
                    />
                  ) : (
                    <Form.Group
                      as={Col}
                      className={styles.mobCountryCodeDropDown}
                      controlId='formGridPhoneNumber'
                    >
                      <div
                        className={styles.mobileCountryCodes}
                        onClick={() =>
                          this.modalToggle('countryCode', 'mobCountryCodeLabel', values.countryCode)
                        }
                      >
                        {this.state.mobCountryCodeLabel}
                        <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                      </div>
                      {this.state.modalShow && (
                        <CountryCodeModal
                          subShow={this.state.modalShow}
                          SubOnHide={this.modalHide}
                          title={this.state.modalTitle}
                          subTitle={this.state.modalSubTitle}
                          modalLabel={this.state.modalLabel}
                          defaultValue={this.state.defaultValue}
                          handleCodeChange={this.setCountryCodeValue}
                          {...this.props}
                        />
                      )}
                    </Form.Group>
                  )}
                  <Form.Group as={Col} controlId='formGridPhoneNumber'>
                    <Field
                      type='text'
                      name='phoneNumber'
                      className={
                        values.phoneNumberExists || !values.isValidPhone ? styles.emailError : null
                      }
                      component={ValidatedFormInputField}
                      fieldWithoutSpace={false}
                      onBlur={(e) => {
                        this.props?.handleBlur(e);
                        const trimmedVal = setValue('phoneNumber', values?.phoneNumber, false);
                        isTrimmedPhoneValid(
                          values.countryCode,
                          values.phoneNumber,
                          setFieldValue,
                          'isValidPhone'
                        );
                        if (trimmedVal && values.isValidPhone && values.countryCode) {
                          let intlPhoneNumber = `${values.countryCode + '' + trimmedVal}`;
                          checkCandidatePhoneAPI(intlPhoneNumber, this.props.candidateDetails?.id)
                            .then((response) => {
                              if (response.data.data.data !== null) {
                                setFieldValue('phoneNumberExists', true);
                              } else {
                                setFieldValue('phoneNumberExists', false);
                              }
                            })
                            .catch((error) => {
                              setFieldValue('phoneNumberExists', false);
                            });
                        }
                      }}
                      regex={allowOnlyNumbersRegex}
                      isHyphenNotRequired
                    />
                    {!errors.phoneNumber && !values.isValidPhone && values.phoneNumber && (
                      <div className={styles.emailErrorMsg}>
                        {!values.isValidPhone ? 'Please provide a valid Phone number.' : ''}
                      </div>
                    )}
                    {values.phoneNumberExists && values.phoneNumber && (
                      <div className={styles.emailErrorMsg}>Phone number already exists.</div>
                    )}
                  </Form.Group>
                </Form.Row>
                <Form.Label>Alternate Phone Number</Form.Label>
                <Form.Row>
                  {!isMobileOnly ? (
                    <Field
                      type='text'
                      name='altCountryCode'
                      defaultValue={values.altCountryCode}
                      options={countryNames}
                      className='countryCodeDropDown'
                      filterLabel={countryOptions}
                      component={CustomValidatedSelect}
                      onChange={(e) => {
                        if (values.altPhone && e) {
                          isTrimmedPhoneValid(e, values.altPhone, setFieldValue, 'isAltValidPhone');
                        }
                      }}
                    />
                  ) : (
                    <Form.Group
                      as={Col}
                      className={styles.mobCountryCodeDropDown}
                      controlId='formGridPhoneNumber'
                    >
                      <div
                        className={styles.mobileCountryCodes}
                        onClick={() =>
                          this.modalToggle(
                            'altCountryCode',
                            'mobAltCountryCodeLabel',
                            values.altCountryCode
                          )
                        }
                      >
                        {this.state.mobAltCountryCodeLabel}
                        <Image className={styles.countryCodeArrow} name='dropdownArrow.svg' />
                      </div>
                    </Form.Group>
                  )}
                  <Form.Group as={Col} controlId='formGridAltPhoneNumber'>
                    <Field
                      type='text'
                      name='altPhone'
                      className={!values.isAltValidPhone ? styles.emailError : null}
                      component={ValidatedFormInputField}
                      onBlur={(e) => {
                        this.props.handleBlur(e);
                        setValue('altPhone', values?.altPhone, false);
                        isTrimmedPhoneValid(
                          values.altCountryCode,
                          values?.altPhone,
                          setFieldValue,
                          'isAltValidPhone'
                        );
                      }}
                      isHyphenNotRequired
                      regex={allowOnlyNumbersRegex}
                    />
                    {!errors.altPhone && !values.isAltValidPhone && (
                      <div className={styles.emailErrorMsg}>
                        {!values.isAltValidPhone ? 'Please provide a valid Phone Number.' : ''}
                      </div>
                    )}
                  </Form.Group>
                </Form.Row>

                <Form.Label>Employee ID</Form.Label>
                <Form.Row>
                  <Field
                    data-testid='employeeId-field'
                    type='text'
                    name='employeeId'
                    className={values.employeeIdExists ? styles.emailError : null}
                    component={ValidatedFormInputField}
                    errors={errors.employeeId}
                    onBlur={(e) => {
                      const trimmedVal = setValue('employeeId', values?.employeeId, true);
                      this.props?.handleBlur(e);
                      if (values.employeeId && !errors.employeeId) {
                        if (
                          this.props?.editTitle !== 'Edit' ||
                          (this.props?.editTitle === 'Edit' &&
                            this.props?.candidateDetails &&
                            this.props?.candidateDetails?.companyCandidateMapping &&
                            this.props?.candidateDetails?.companyCandidateMapping?.employeeId !==
                              trimmedVal)
                        ) {
                          checkCandidateEmployeeIdAPI(trimmedVal).then((response) => {
                            if (response.data.data.data) {
                              setFieldValue('employeeIdExists', true);
                            } else {
                              setFieldValue('employeeIdExists', false);
                            }
                          });
                        }
                      }
                    }}
                  />
                  {!errors.employeeId && values.employeeIdExists && (
                    <div className={styles.emailErrorMsg}>
                      {values.employeeIdExists ? 'Employee Id already exists' : ''}
                    </div>
                  )}
                </Form.Row>
                <Form.Row>
                  <Form.Label>Universal Account Number</Form.Label>
                  <Field
                    data-testid='uan-field'
                    type='text'
                    name='uanNumber'
                    component={ValidatedFormInputField}
                    onBlur={(e) => {
                      this.props?.handleBlur(e);
                      setValue('uanNumber', values?.uanNumber, false);
                    }}
                    isHyphenNotRequired
                    max={12}
                    regex={allowOnlyNumbersRegex}
                  />
                </Form.Row>
                <Form.Group>
                  <Form.Label>
                    Date of Joining {values?.formConfig?.isDojMandatory ? <span>*</span> : null}
                  </Form.Label>
                  <div>
                    <DatePicker
                      placeholderText={'Date of Joining'}
                      // popperClassName={styles.datePicker1}
                      selected={values.doj ? moment(values.doj).toDate() : null}
                      dateFormat='dd MMM yyyy'
                      isClearable={values.doj}
                      className={styles.datePicker}
                      popperPlacement='auto'
                      onChange={(date) => {
                        setFieldValue('doj', date ? moment(date).format('YYYY-MM-DD') : '');
                      }}
                      onBlur={(e) => {
                        this.props?.handleBlur(e);
                      }}
                      onChangeRaw={(e) => e.preventDefault()}
                      maxDate={new Date()}
                      peekNextMonth
                      showMonthDropdown
                      showYearDropdown
                      dropdownMode='select'
                    />
                  </div>
                </Form.Group>
                {errors?.doj && (
                  <div className={styles.emailErrorMsg}>{'Date of joining is required.'}</div>
                )}
                <Form.Label className={styles.required}>Sex</Form.Label>
                <div className={styles.radioBtn}>
                  <Field
                    data-testid='gender-field'
                    name='gender'
                    component={ValidatedRadioFields}
                    options={[
                      { value: 1, label: 'Male' },
                      { value: 2, label: 'Female' },
                      { value: 3, label: 'Non-binary' },
                      { value: 4, label: 'Prefer not to disclose' }
                    ]}
                  />
                  <div className={styles.genderRadioError}>
                    <ErrorMessage name='gender' />
                  </div>
                </div>
                <Form.Label>
                  Resume {!this.state.formConfig.isResumeOptional ? <span>*</span> : null}
                </Form.Label>
                <Field
                  data-testid='resume-field'
                  name='resume'
                  files='resumeFile'
                  component={ValidatedFileSelect}
                  defaultField={false}
                  candidateId={this.props?.candidateDetails?.id}
                  setIsUploading={this.setIsUploading}
                  resumeProps={
                    this.props?.candidateDetails?.companyCandidateMapping?.resumeFormat !== null
                      ? this.props?.candidateDetails?.companyCandidateMapping
                      : null
                  }
                  uploadToServer={'s3Upload'}
                  linkFieldName={'resumeLink'}
                  handleErrorCallback={(value) => {
                    this.setResumeError(value);
                  }}
                />
                <div className={styles.resumeErrorMsg}>
                  <ErrorMessage name='resume' />
                </div>
                <Form.Group>
                  <Form.Label>Category</Form.Label>
                  <AdminModals
                    show={this.state.modalShowStatus}
                    onHide={this.modalHideStatus}
                    content={
                      this.state.modalTitle === 'Create Tag'
                        ? 'Tag created successfully'
                        : 'Category created successfully'
                    }
                  />
                  <CategoryModals
                    show={this.state.modalShow}
                    onHide={this.modalHide}
                    title={this.state.modalTitle}
                    subTitle={this.state.modalSubTitle}
                    button={this.state.modalButton}
                    placeholder={this.state.modalPlaceholder}
                    toDelete={values}
                    {...this.props}
                  />

                  <Form.Row>
                    <Col lg={this.state.isAdmin ? 9 : 12} md={this.state.isAdmin ? 9 : 12}>
                      <Select
                        isClearable={true}
                        placeholder='None'
                        menuPlacement='top'
                        isLoading={this.state.reloadingConfig}
                        options={
                          this.state.reloadingConfig
                            ? [{ value: '', label: 'Loading...', isDisabled: true }]
                            : this.state.categories
                        }
                        onMenuOpen={handleReloadCategories}
                        noOptionsMessage={() => 'No Categories found'}
                        styles={customStyles}
                        value={this.state.categories.find((c) => c.value === values?.category)}
                        error={this.state.deletedCatError}
                        onChange={(value) => {
                          validateCategoryOnChange(value?.value);
                          setFieldValue('categoryName', value?.label || '');
                          setFieldValue('category', value?.value || '');
                          this.setState({ selectedCategory: value });
                        }}
                      />
                      {this.state.deletedCatError && (
                        <div
                          className={styles.serviceErrorMsg}
                          dangerouslySetInnerHTML={{ __html: this.state.deletedCatError }}
                        ></div>
                      )}
                    </Col>
                    {this.state.isAdmin ? (
                      <Col lg={3} md={3} style={{ padding: 0 }}>
                        <Button
                          onClick={() =>
                            this.modalToggle('Create Category', 'Name', 'Create', 'Category Name')
                          }
                          className={styles.addBtn}
                        >
                          Add new category
                        </Button>
                      </Col>
                    ) : null}
                  </Form.Row>
                </Form.Group>
                <Form.Group>
                  <Form.Label>Tag</Form.Label>
                  <div
                    data-testid='tags-field'
                    style={{ display: 'flex', justifyContent: 'space-between' }}
                  >
                    <div>
                      <Select
                        isMulti
                        placeholder='No tags'
                        name='tag'
                        menuPlacement='top'
                        components={{ IndicatorSeparator }}
                        onMenuOpen={handleReloadTags}
                        closeMenuOnSelect={false}
                        isLoading={this.state.reloadingConfig}
                        options={
                          this.state.reloadingConfig
                            ? [{ value: '', label: 'Loading...', isDisabled: true }]
                            : this.state.tags
                        }
                        styles={customStyles}
                        isClearable={false}
                        error={this.state.deletedTagsError}
                        noOptionsMessage={() => 'No Tags found'}
                        value={this.props?.values?.tag}
                        onChange={(value) => {
                          let newTagList = value || [];
                          validateTagsOnChange(newTagList);
                          setFieldValue('tag', newTagList);
                        }}
                      />
                      {this.state.deletedTagsError && (
                        <div
                          className={styles.serviceErrorMsg}
                          dangerouslySetInnerHTML={{ __html: this.state.deletedTagsError }}
                        ></div>
                      )}
                    </div>

                    {this.state.isAdmin ? (
                      <div style={{ minWidth: 'fit-content' }}>
                        <Button
                          onClick={() =>
                            this.modalToggle('Create Tag', 'Name', 'Create', 'Tag Name')
                          }
                          className={styles.addBtn}
                        >
                          Add new tag
                        </Button>
                      </div>
                    ) : null}
                  </div>
                </Form.Group>
                <div className={styles.btnSection}>
                  <Button
                    className={styles.deleteBtn}
                    onClick={() =>
                      this.modalToggle(
                        'Delete Candidate ',
                        'Are you sure you want to delete this candidate ?',
                        'Delete'
                      )
                    }
                  >
                    Delete
                  </Button>
                  <Button
                    type='button'
                    className={styles.saveBtn}
                    disabled={!isValidForm || isSubmitting || !dirty}
                    onClick={() => {
                      values.isEdit = true;
                      handleFormSubmit();
                    }}
                  >
                    Save
                  </Button>
                </div>
              </Form>
            </Card.Body>
          </Card>
        </div>
      </div>
    );
  }
}
