import React, { useEffect, useState } from 'react';
import ColumnMatcher from '../Components/ColumnMatcher/ColumnMatcher';
import moment from 'moment';
import { getTrimmedValue, getAbsoluteDateFormat } from '../Components/Table/utils';
import '../bulkuploader.css';

const Step2 = ({
  options,
  columns,
  setColumns,
  rows,
  matchedColumns,
  flow,
  usedOptions,
  setUsedOptions,
  setMsg,
  setFinalData
}) => {
  const [isInvalid, setIsInvalid] = useState({
    staff_id: false,
    date_of_birth: false
  });

  const fetchFieldsFromArray = (array, index, dateFormat = 'YYYY-MM-DD', keyx) => {
    const fields = [];

    let invalidCount = 0,
      nullCount = 0,
      totalCount = array.length;

    array.forEach((element) => {
      let value = element[index];
      if (
        value === null ||
        value === undefined ||
        (typeof value === String && value?.trim() === '')
      ) {
        nullCount++;
      } else if (columns[keyx]?.validations?.regex) {
        const regexPattern = new RegExp(columns[keyx].validations.regex);
        const valtoCheck = getTrimmedValue(value, keyx);
        if (!regexPattern.test(String(valtoCheck))) {
          invalidCount++;
        }
      } else if (columns[keyx]?.type === 'date') {
        const date_format = dateFormat ?? 'YYYY-MM-DD'; // Fallback format
        const date = moment(value.replace(/\b\d\b/g, '0$&'), date_format, true);
        if (!date.isValid()) {
          invalidCount++;
        } else {
          value = date.format('YYYY-MM-DD'); // Normalize the date format
        }
      }

      fields.push(typeof value === 'number' ? String(value) : value);
    });
    const takenull = columns[keyx].required ? nullCount : 0;
    return {
      fields,
      value_percentage: ((totalCount - nullCount) / totalCount) * 100,
      validation_percentage: ((totalCount - takenull - invalidCount) / totalCount) * 100
    };
  };

  useEffect(() => {
    if (matchedColumns.length > 0 && usedOptions.length === 0) {
      const tempColumns = { ...columns };
      const tempUsedOptions = new Set();
      Object.values(
        matchedColumns.reduce((acc, obj) => {
          acc[obj.matched_column] = obj;
          return acc;
        }, {})
      )
        .map((item) => {
          const matchedOption = options.find(
            (option) =>
              item.matched_column?.toLowerCase() !== 'ignore' &&
              option.label
                ?.toLowerCase()
                ?.replaceAll('(yyyy-mm-dd)', '')
                ?.replaceAll(/[\n\r* ]/g, '') ===
                item.matched_column
                  ?.toLowerCase()
                  ?.replaceAll('(yyyy-mm-dd)', '')
                  ?.replaceAll(/[\n\r* ]/g, '')
          );
          return {
            ...item,
            matched_column: matchedOption ? matchedOption : null
          };
        })
        .forEach((e) => {
          if (e.matched_column) {
            tempUsedOptions.add(e.matched_column.value);
            const index = e.matched_column.value;
            let dateF = e.date_format;
            if (columns[e.key]?.type === 'date') {
              let firstValue = rows.find(
                (row) =>
                  row[e.matched_column.value] !== null &&
                  row[e.matched_column.value] !== undefined &&
                  row[e.matched_column.value].trim() !== ''
              );
              const absoluteDateFormat = getAbsoluteDateFormat(
                firstValue?.[e?.matched_column?.value]
              );
              if (absoluteDateFormat) {
                dateF = absoluteDateFormat;
              }
            }
            const rowData = fetchFieldsFromArray(rows, index, dateF, e.key);
            tempColumns[e.key].selectedHeader = e.matched_column;
            tempColumns[e.key].rowData = rowData.fields;
            tempColumns[e.key].value_percentage = rowData.value_percentage;
            tempColumns[e.key].validation_percentage = rowData.validation_percentage;
            if (dateF) {
              tempColumns[e.key].date_format = dateF;
            }
          }
        });
      setColumns(tempColumns);
      setUsedOptions([...tempUsedOptions]);
    }
  }, [matchedColumns]);

  useEffect(() => {
    if (isInvalid?.staff_id || isInvalid?.date_of_birth) {
      setMsg({
        load: false,
        msg: '',
        disableSubmits: true
      });
    } else {
      setMsg({
        load: false,
        msg: '',
        disableSubmits: false
      });
    }
  }, [isInvalid]);

  const countSelectedFields = () =>
    Object.values(columns).reduce((acc, column) => (column.selectedHeader ? acc + 1 : acc), 0);
  const isStaffIdOrDobAvailable =
    columns['staff_id']?.selectedHeader || columns['date_of_birth']?.selectedHeader;

  useEffect(() => {
    const ignoredOpted = { staffId: false, dob: false };
    const isNoOptionSelected = { staffId: false, dob: false };

    const checkSelection = (column, key) => {
      if (column) {
        if (column?.selectedHeader === null || column?.selectedHeader === undefined) {
          isNoOptionSelected[key] = true;
        }
        if (column?.selectedHeader?.value === -1) {
          ignoredOpted[key] = true;
        }
      }
    };

    checkSelection(columns.staff_id, 'staffId');
    checkSelection(columns.date_of_birth, 'dob');

    if (ignoredOpted.staffId && ignoredOpted.dob) {
      setIsInvalid({ staff_id: true, date_of_birth: true });
    } else if (ignoredOpted.staffId && isNoOptionSelected.dob) {
      setIsInvalid({ staff_id: false, date_of_birth: true });
    } else if (ignoredOpted.dob && isNoOptionSelected.staffId) {
      setIsInvalid({ staff_id: true, date_of_birth: false });
    } else {
      setIsInvalid({ staff_id: false, date_of_birth: false });
    }
  }, [columns]);

  return (
    <div className='step2Container'>
      <div className='Step2Header'>
        <div>Please match the fields in our database with columns of your file.</div>
        <div className='Step2Match'>
          {countSelectedFields()}/{Object.keys(columns).length} Columns Matched
        </div>
      </div>
      <div className='step2'>
        {Object.keys(columns).map((key, index) => {
          return (
            <ColumnMatcher
              key={index}
              id={key}
              key_index={index}
              column={columns[key]}
              options={options}
              rows={rows}
              setColumn={(column) => {
                const updatedColumns = { ...columns, [key]: column };
                setColumns(updatedColumns);
              }}
              setFinalData={setFinalData}
              usedOptions={usedOptions}
              setUsedOptions={setUsedOptions}
              isStaffIdOrDobAvailable={isStaffIdOrDobAvailable}
              flow={flow}
              isInvalid={isInvalid}
            />
          );
        })}
        <div className='spacer' style={{ height: '50px' }}></div>
      </div>
    </div>
  );
};

export default Step2;
