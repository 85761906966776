// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.fe7Wx9u7CSY6OntPqQWT .xwcEO438NLGgqXudOQPo form label {
  font-family: Poppins, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 21px;
  color: #646c9a;
  margin-bottom: 6px;
}
.fe7Wx9u7CSY6OntPqQWT .xwcEO438NLGgqXudOQPo form label span {
  color: red;
}
.fe7Wx9u7CSY6OntPqQWT .xwcEO438NLGgqXudOQPo form .X8cqpaqGlsDiFW27J743 {
  justify-content: space-between !important;
}

.bOkGtY6UH6LP5ZSQ1RtW {
  margin-top: 32px;
}
.bOkGtY6UH6LP5ZSQ1RtW .pceJeO5gK9LJ39JfvnHR {
  border: 1px solid #f20000;
  border-radius: 5px;
  background: #ffffff;
  width: 130px;
  height: 40px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #d3455b;
}
.bOkGtY6UH6LP5ZSQ1RtW .pceJeO5gK9LJ39JfvnHR:active {
  color: #f20000 !important;
  background-color: #ffffff !important;
  border-color: #f20000 !important;
}
.bOkGtY6UH6LP5ZSQ1RtW .H2yoUsWcemE9hONLzorF {
  float: right;
  background: #2755fe;
  border-radius: 5px;
  width: 130px;
  height: 40px;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #ffffff;
}

.bj2mgc1zbRAdXwzNPdhM {
  height: 38px;
  background: transparent;
  font-family: Poppins, sans-serif;
  font-size: 14px;
  line-height: 20px;
  color: #2755fe;
}
.bj2mgc1zbRAdXwzNPdhM:active, .bj2mgc1zbRAdXwzNPdhM:hover {
  color: #2755fe !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

._zQvdJTsAiUUy1x3gkW2 {
  padding: 0 28px;
  margin-bottom: 40px;
}

.YqCtCY_j0xjzFgaU6Szv {
  margin-top: 32px;
  position: relative;
}

.MQuOZqZtaK07vt8Qc2fp {
  position: absolute;
  left: 190px;
  z-index: 1;
}

.LJa8kO_qHNR5ACT36gFp {
  font-size: 80%;
  color: #dc3545;
  margin-top: -20px;
  margin-left: -28px;
  margin-bottom: -20px;
}

.aJTVgeKvb37Av9keFpcL input {
  border: 1px solid #dc3545 !important;
  margin-bottom: 0 !important;
}

.Iz1GMLp5A8qpxW7X0y5K {
  font-size: 14px;
  color: #dc3545;
  margin-top: -11px !important;
}

.S_0cDQgHEGrwzejOUf7Q {
  font-size: 13px;
  color: #dc3545;
}

.w1uxXjopYxsq2FqtZZtW {
  font-size: 13px;
  color: #dc3545;
  margin-top: -30px !important;
  margin-bottom: 28px;
}

.bsRPPYc1AP5XJCmVxwT3 {
  border: 1px solid #ebedf2;
  border-radius: 4px;
  background-color: var(--white);
  font-size: var(--para-font-size);
  font-weight: normal;
  line-height: 45px;
  height: 38px;
  width: 100%;
  color: #4c4c4c;
  padding: 0 12px;
  margin-bottom: "12px";
}
.bsRPPYc1AP5XJCmVxwT3::placeholder {
  color: red;
}
.bsRPPYc1AP5XJCmVxwT3 input:disabled {
  box-shadow: inset 0 2px 2px #e9e9e9;
  border: 1px solid #aeaeae;
  color: -internal-light-dark-color(rgb(84, 84, 84), rgb(170, 170, 170));
}
.bsRPPYc1AP5XJCmVxwT3 input:disabled:hover {
  cursor: not-allowed;
}

.iy5D32E_RXMSp7NjS6qO {
  margin-bottom: 32px;
}
@media screen and (max-width: 601px) {
  .iy5D32E_RXMSp7NjS6qO {
    margin-bottom: 3px;
  }
}
.iy5D32E_RXMSp7NjS6qO .NOKNGdhhAg47KaKTBsta {
  border: 1px solid #ebedf2;
  border-radius: 3px;
  height: 35px;
  font-weight: 100;
  padding-left: 5px;
}
.iy5D32E_RXMSp7NjS6qO .oDPcVv0d79JyE_zBg7Sn {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
}`, ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"addCandidate": `fe7Wx9u7CSY6OntPqQWT`,
	"cardSection": `xwcEO438NLGgqXudOQPo`,
	"form-row": `X8cqpaqGlsDiFW27J743`,
	"btnSection": `bOkGtY6UH6LP5ZSQ1RtW`,
	"deleteBtn": `pceJeO5gK9LJ39JfvnHR`,
	"saveBtn": `H2yoUsWcemE9hONLzorF`,
	"addBtn": `bj2mgc1zbRAdXwzNPdhM`,
	"radioBtn": `_zQvdJTsAiUUy1x3gkW2`,
	"formConsent": `YqCtCY_j0xjzFgaU6Szv`,
	"identityDropdown": `MQuOZqZtaK07vt8Qc2fp`,
	"genderRadioError": `LJa8kO_qHNR5ACT36gFp`,
	"emailError": `aJTVgeKvb37Av9keFpcL`,
	"emailErrorMsg": `Iz1GMLp5A8qpxW7X0y5K`,
	"serviceErrorMsg": `S_0cDQgHEGrwzejOUf7Q`,
	"resumeErrorMsg": `w1uxXjopYxsq2FqtZZtW`,
	"datePicker": `bsRPPYc1AP5XJCmVxwT3`,
	"formGroupDate": `iy5D32E_RXMSp7NjS6qO`,
	"datePickerStyle": `NOKNGdhhAg47KaKTBsta`,
	"datePickerHeaderStyle": `oDPcVv0d79JyE_zBg7Sn`
};
export default ___CSS_LOADER_EXPORT___;
