import React, { useEffect, useState, useRef } from 'react';
import Image from '../../core-components/Image';
import Loader from '../../../../../core-components/Loader';
import AppConstants from '../../../../../core-components/AppConstants';
import styles from './Repute.module.scss';
import { getIntegrationFieldSubtypeMapping } from '../../../../../api/company';

import { Modal } from 'react-bootstrap';

export default function Repute(props) {
  const code = new URLSearchParams(document.location.search).get('code');
  const company_id = new URLSearchParams(document.location.search).get('company_id');
  const hrms_id = new URLSearchParams(document.location.search).get('hrms_id');
  const vault_url = new URLSearchParams(document.location.search).get('vault_url');
  const hrms_name = new URLSearchParams(document.location.search).get('hrms_name');
  const hrms_logo_url = new URLSearchParams(document.location.search).get('hrms_logo_url');

  const [openError, setOpenError] = useState(false);
  const [integrationError, setIntegrationError] = useState({});

  const params = new URLSearchParams(window.location.search);

  const initializeRepute = async () => {
    const values = {
      auth_code: code,
      repute_company_id: company_id,
      hrms_id: hrms_id,
      vault_url: vault_url,
      hrms_logo_url,
      hrms_name,
      params
    };
    props.initializeRepute(values).then((response) => {
      if (response?.error) {
        // Error handling when the integration fails, as User is not a valid SpringVerify user
        if (parseInt(response?.error?.status, 10) === 404) {
          setIntegrationError({
            title: `Integration with ${hrms_name?.toUpperCase()} has been initialized.`,
            subtitle: `Team will reach out to you within 48 hours to complete this integrations. For any
                  query contact <b>cs@springverify.com</b> or your HR team.`
          });
        } else if (parseInt(response?.error?.status, 10) === 400) {
          // Error handling when the integration fails, while generating the token for the integration
          setIntegrationError({
            title: `Error while initializing the Integration with ${hrms_name?.toUpperCase()}`,
            subtitle: `An error occurred while generating the token for the integration. `
          });
        }

        setOpenError(true);
      } else {
        const companyId = response?.data?.company_user_mapping?.company_id;
        getIntegrationFieldSubtypeMapping({
          company_id: companyId,
          integrationName: 'REPUTE'
        })
          .then((response) => {
            const isPackageMappingPresent = response.data?.length > 0;
            if (isPackageMappingPresent) {
              window.location.href = `${AppConstants.baseURL}dashboard`;
            } else {
              window.location.href = `${AppConstants.baseURL}integrations/repute`;
            }
          })
          .catch((error) => {
            console.log('error', error);
          });
      }
    });
  };

  useEffect(() => {
    initializeRepute();
  }, []);

  return (
    <Modal size='lg' show={true} centered backdrop='static' className='integration-modal'>
      {!openError ? (
        <Modal.Body>
          <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
              <div className={styles.iconContainer}>
                <Image className={styles.largeImage} name='springverify.svg' alt='Logo 1' />
                <Image className={styles.mediumImage} name='direction.svg' alt='Logo 1' />
                <img className={styles.largeImage} src={hrms_logo_url} alt='Logo 2' />
              </div>
            </div>
            <div className={styles.loaderContainer}>
              <Loader />
            </div>
            <div className={styles.bottomContainer}>
              <div className={styles.textContainer}>
                <p className={styles.headerText}>Initializing the integration with {hrms_name}.</p>
                <p className={styles.bodyText}>Please wait while we set up the integration.</p>
              </div>
            </div>
          </div>
        </Modal.Body>
      ) : (
        <Modal.Body>
          <div className={styles.outerContainer}>
            <div className={styles.innerContainer}>
              <div className={styles.iconContainer}>
                <Image className={styles.largeImage} name='springverify.svg' alt='Logo 1' />
                <Image className={styles.mediumImage} name='direction.svg' alt='Logo 1' />
                <img className={styles.largeImage} src={hrms_logo_url} alt='Logo 2' />
              </div>
            </div>
            <div className={styles.loaderContainer}>
              <Image className={styles.smallImage} name='processed.svg' alt='Logo 2' />
            </div>
            <div className={styles.bottomContainer}>
              <div className={styles.strictTextContainer}>
                <p className={styles.headerText}>
                  {integrationError?.title ||
                    `Integration with ${hrms_name?.toUpperCase()} has been
                  initialized.`}
                </p>
                <p
                  className={styles.bodyText}
                  dangerouslySetInnerHTML={{
                    __html:
                      integrationError?.subtitle ||
                      `Team will reach out to you within 48 hours to complete this integration. 
      For any query contact <b>cs@springverify.com</b> or your HR team.`
                  }}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      )}
    </Modal>
  );
}
