/* eslint-disable no-unused-expressions */
import React, { useState } from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import Button from 'react-bootstrap/Button';

import Image from '../../../../core-components/Image';
import { convertToIndianNumeration, jwtParser } from '../../../../utils/utilities';
import localStorage from '../../../../api/localStorage';

import styles from './DropdownMenu.module.scss';
import { Link } from 'react-router-dom';
import {
  CA_REFERRAL_AMOUNT,
  CLIENT_KB_URL,
  CONTACT_SUPPORT,
  HEAD_CSAM
} from '../../../../utils/commonConstant';
import { Modal } from 'react-bootstrap';
import ContactCard from '../components/ContactCard';

const CustomToggle = React.forwardRef(({ children, onClick }, ref) => (
  <Button
    ref={ref}
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

const CustomMenu = React.forwardRef(
  ({ children, style, className, 'aria-labelledby': labeledBy }, ref) => {
    let newStyle = {
      bottom: 'auto',
      left: '0',
      position: 'absolute',
      right: 'auto',
      top: '0',
      transform: 'translate3d(-300.6666666666667px, 50px, 0)'
    };
    return (
      <div ref={ref} style={newStyle} className={className}>
        <div>{React.Children.toArray(children)}</div>
      </div>
    );
  }
);

export default ({ title, company, pointOfContact, ...props }) => {
  const token = localStorage.getToken();
  const parsedJwtDetail = jwtParser(token);
  const [showHelp, setShowHelp] = useState(false);
  const mapContactData = () => {
    const contactMapping = [];
    const sanitizeName = (name) => name.replace(/^C S\s*/, '');

    // Map CSA
    if (pointOfContact?.customerSuccessAssociate?.name) {
      contactMapping.push({
        name: sanitizeName(pointOfContact?.customerSuccessAssociate.name),
        role: 'Customer Success Associate',
        helpText: 'For BGV-related queries that might need a discussion.',
        email: pointOfContact?.customerSuccessAssociate?.email || '',
        phone: pointOfContact?.customerSuccessAssociate?.mobile || ''
      });
    }
    // Map csm
    if (pointOfContact?.accountExecutive?.name) {
      contactMapping.push({
        name: sanitizeName(pointOfContact?.accountExecutive.name),
        role: 'Customer Success Manager',
        helpText: 'For Recharges, Package Updates, Additional Checks, and Business Reviews.',
        email: pointOfContact?.accountExecutive?.email || '',
        phone: pointOfContact?.accountExecutive?.mobile || ''
      });
    }

    return contactMapping;
  };

  const contactData = mapContactData();
  return title ? (
    <div className={styles.userProfile}>
      <Dropdown className={styles.mainDropdown}>
        <Dropdown.Toggle as={CustomToggle} id='dropdown-custom-components'>
          {title && title.charAt(0)}
        </Dropdown.Toggle>
        <Dropdown.Menu as={CustomMenu} className={styles.dropdownMenu}>
          <div className={styles.dropdownMenuHeader}>
            <div className={styles.userInitial}>{title && title.charAt(0)}</div>
            <div className={styles.userDetails}>
              <div className={styles.name}>{title}</div>
              <div>
                <Dropdown.Item className={styles.myProfileLink}>
                  <Link className={styles.myProfile} to={{ pathname: '/profile' }}>
                    {'My Profile'}
                  </Link>
                </Dropdown.Item>
              </div>
            </div>
          </div>
          {props?.isAdmin ? (
            <>
              {props?.paymentDetails?.userEmailBounceData?.count ? (
                <Dropdown.Item
                  className={`${styles.dropdownParticularCard} ${styles.adminBounceEmailBanner}`}
                  onClick={() => props.history.push('/myTeam')}
                >
                  <div className={styles.dropdownParticular}>
                    <Image name={'myTeamIconWhite.svg'} />
                    <span className={styles.userSectionDetails}>
                      <div style={{ display: 'flex' }}>
                        <p className={styles.userSectionHeader}>{'My Team'}</p>
                        <Image name={'whiteWarning.svg'} style={{ marginLeft: '3px' }} />
                      </div>
                      <p className={styles.userSectionDescription}>{'Manage users in your team'}</p>
                    </span>
                  </div>
                </Dropdown.Item>
              ) : (
                <Dropdown.Item
                  className={styles.dropdownParticularCard}
                  onClick={() => props.history.push('/myTeam')}
                >
                  <div className={styles.dropdownParticular}>
                    <Image name={'myTeamIcon.svg'} />
                    <span className={styles.userSectionDetails}>
                      <p className={styles.userSectionHeader}>{'My Team'}</p>
                      <p className={styles.userSectionDescription}>{'Manage users in your team'}</p>
                    </span>
                  </div>
                </Dropdown.Item>
              )}
              <Dropdown.Item
                className={styles.dropdownParticularCard}
                onClick={() => props.history.push('/manageTagsCategories')}
              >
                <div className={styles.dropdownParticular}>
                  <Image name={'manageTagsIcon.svg'} />
                  <span className={styles.userSectionDetails}>
                    <p className={styles.userSectionHeader}>{'Manage Tags & Categories'}</p>
                    <p className={styles.userSectionDescription}>
                      {'Add, Edit or Delete tags and categories'}
                    </p>
                  </span>
                </div>
              </Dropdown.Item>{' '}
            </>
          ) : null}

          <Dropdown.Item
            className={styles.dropdownParticularCard}
            onClick={() => props.history.push('/candidateAnalytics')}
          >
            <div className={styles.dropdownParticular}>
              <div className={styles.candidateAnalyticsIcon}>
                <Image width={24} height={24} name={'candidateAnalytics.png'} />
              </div>
              <span className={styles.userSectionDetails}>
                <p className={styles.userSectionHeader}>{'Candidate Analytics'}</p>
                <p className={styles.userSectionDescription}>
                  {'Track cases that need your attention'}
                </p>
              </span>
            </div>
          </Dropdown.Item>

          {props?.isAdmin ? (
            <>
              {parsedJwtDetail?.data?.modelType?.includes('PREPAID') && props?.isAdmin && (
                /**
                 * This block has been commented as per the feature ADO-3138
                 * 
                <>
                  <Dropdown.Item
                    className={styles.dropdownParticularCard}
                    href={`${AppConstants.baseURL}purchaseHistory`}
                  >
                    <div className={styles.dropdownParticular}>
                      <Image name={'purchaseHistoryIcon.svg'} />
                      <span className={styles.userSectionDetails}>
                        <p className={styles.userSectionHeader}>{'Purchase history'}</p>
                        <p className={styles.userSectionDescription}>
                          {'Check packages & credits purchased'}
                        </p>
                      </span>
                    </div>
                  </Dropdown.Item>
                  <Dropdown.Item
                    className={styles.dropdownParticularCard}
                    href={`${AppConstants.baseURL}credits`}
                  >
                    <div className={styles.dropdownParticular}>
                      <Image name={'creditsIcon.svg'} />
                      <span className={styles.userSectionDetails}>
                        <p className={styles.userSectionHeader}>{'Credits'}</p>
                        <p className={styles.userSectionDescription}>
                          {'Add credits, check balance & transaction'}
                        </p>
                      </span>
                    </div>
                  </Dropdown.Item>
                </>
                 **/
                <Dropdown.Item
                  className={styles.dropdownParticularCard}
                  onClick={() => props.history.push('/transaction')}
                >
                  <div className={styles.dropdownParticular}>
                    <div className={styles.transactionIcon}>
                      <Image width='25px' height='29px' name={'transactions.svg'} />
                    </div>
                    <span className={styles.userSectionDetails}>
                      <p className={styles.userSectionHeader}>{'Transactions'}</p>
                      <p className={styles.userSectionDescription}>
                        {'Track purchases, usage logs, & orders'}
                      </p>
                    </span>
                  </div>
                </Dropdown.Item>
              )}
            </>
          ) : null}
          <div className={styles.bulkUploadItemContainer}>
            <div className={styles.customTooltipContainer}>
              <div className={styles.customTooltip}>
                <div className={styles.bcTT}>This option has moved to the Add Candidate Button</div>
                <Dropdown.Item
                  className={styles.addCandidateLink}
                  onClick={() => props.history.push('/candidates?showCandidateTooltip=yes')}
                >
                  Take me there
                </Dropdown.Item>
              </div>
            </div>
            {/* <Dropdown.Item
              className={styles.dropdownParticularCard + ' ' + styles.disabledItem}
              disabled={true}
            >
              <div className={styles.dropdownParticular}>
                <Image name={'bulkCandidateNew.svg'} />
                <span className={styles.userSectionDetails}>
                  <p className={styles.userSectionHeader}>{'Bulk Candidate Addition'}</p>
                  <p className={styles.userSectionDescription}>
                    {'Upload multiple candidates at once'}
                  </p>
                </span>
              </div>
            </Dropdown.Item> */}
          </div>
          {props?.isAdmin ? (
            /* API token Generation can only be done by CA-Admin */
            <Dropdown.Item
              className={styles.dropdownParticularCard}
              onClick={() => props.history.push('/settings')}
            >
              <div className={styles.dropdownParticular}>
                <Image
                  style={{ height: '45px', width: '29px', padding: '6px 0px' }}
                  name={'settings.svg'}
                />
                <span className={styles.userSectionDetails}>
                  <p className={styles.userSectionHeader}>{'Settings'}</p>
                  <p className={styles.userSectionDescription}>
                    {'Configure candidate and user settings'}
                  </p>
                </span>
              </div>
            </Dropdown.Item>
          ) : null}
          {props?.isAdmin ? (
            /* Integrations can only be done by CA-Admin */
            <Dropdown.Item
              className={styles.dropdownParticularCard}
              onClick={() => props.history.push('/integrations')}
            >
              <div className={styles.dropdownParticular}>
                <Image name={'integrations.svg'} />
                <span className={styles.userSectionDetails}>
                  <p className={styles.userSectionHeader}>{'Integrations'}</p>
                  <p className={styles.userSectionDescription}>{'Integrate to SpringVerify'}</p>
                </span>
              </div>
            </Dropdown.Item>
          ) : null}
          <Dropdown.Item
            className={styles.dropdownParticularCard}
            onClick={() => props.history.push('/exportCases')}
          >
            <div className={styles.dropdownParticular}>
              <Image name={'exportCasesIcon.svg'} />
              <span className={styles.userSectionDetails}>
                <p className={styles.userSectionHeader}>{'Export Cases'}</p>
                <p className={styles.userSectionDescription}>{'Export candidate data to Excel'}</p>
              </span>
            </div>
          </Dropdown.Item>
          {props?.paymentDetails?.isReferralEnabled ? (
            <Dropdown.Item
              className={styles.dropdownParticularCard}
              onClick={() => props.history.push('/refer-and-earn')}
            >
              <div className={styles.dropdownParticular}>
                <Image name={'referAndEarn.svg'} />
                <span className={styles.userSectionDetails}>
                  <p className={styles.userSectionHeader}>{'Refer and Earn'}</p>
                  <p className={styles.userSectionDescription}>
                    Get
                    <span className={styles.descAmount}>
                      &#8377;{convertToIndianNumeration(CA_REFERRAL_AMOUNT)}
                    </span>
                    for each HR that you refer
                  </p>
                </span>
              </div>
            </Dropdown.Item>
          ) : null}
          {/* Commenting out the Slack Connect Banner under IMP-21002 */}
          {/* <Dropdown.Item
            className={styles.dropdownParticularCard}
            onClick={() => {
              handleSlackConnect();
            }}
          >
            <div className={styles.dropdownParticular}>
              <Image name={'slacknew.svg'} style={{ width: '20px' }} />
              <span className={styles.userSectionDetails}>
                <p className={styles.userSectionHeader}>{'Message us on Slack'}</p>
                <p className={styles.userSectionDescription}>
                  {'Connect with your Account Manager'}
                </p>
              </span>
            </div>
          </Dropdown.Item> */}
          <div className={styles.dropdownParticularCard}>
            <div className={styles.signOutBtn} onClick={() => props.history.push('/logout')}>
              {'Sign Out'}
            </div>
            <div className={styles.helpBtn} onClick={() => setShowHelp(true)}>
              Need Help?
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
      {showHelp && (
        <Modal show={showHelp} onHide={() => setShowHelp(false)} size='xl' centered>
          <Modal.Header closeButton>
            <Modal.Title id='contained-modal-title-vcenter'>
              <div className={styles.modalTitle}>
                <span className={styles.modalTitleText}>Need Help?</span>
                <span className={styles.modalSubtitleText}>We Got You!</span>
              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className={styles.helpBodyContainer}>
              <div className={styles.kbBanner}>
                <Image name='knowledgebase.svg' className={styles.kbBannerImage} />
                <span className={styles.knBannerText}>
                  Refer to our knowledge base to know more
                </span>
                <Image
                  name='arrowRight.svg'
                  className={styles.knowMoreImage}
                  onClick={() => window.open(CLIENT_KB_URL, '_blank')}
                />
              </div>
              <div className={styles.helpTextHeadingContainer}>
                <span className={styles.helpTextHeading}>Please feel free to reach out to us</span>
                <div className={styles.horizontalLine}></div>
              </div>
              <div className={styles.helpCardContainer}>
                <ContactCard contactData={CONTACT_SUPPORT} />
                {contactData.map((contact, index) => (
                  <ContactCard contactData={contact} key={index} />
                ))}
                <ContactCard contactData={HEAD_CSAM} />
              </div>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  ) : (
    <div />
  );
};
