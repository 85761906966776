import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import Modal from 'react-bootstrap/Modal';
import { Col, Button, Container, Row } from 'react-bootstrap';

import Identity from '../../../../../core-components/Packages/Identity';
import Address from '../../../../../core-components/Packages/Address';
import Reference from '../../../../../core-components/Packages/Reference';

import styles from './Customize.module.scss';

export default (props) => {
  const { handleSubmit, isSubmitting, values, errors, setFieldValue, pDetails, candidateConfig } =
    props;

  const handleConfigChange = (type, name, value) => {
    setFieldValue(`${type}[${name}]`, value);
  };
  const isRefError = () => {
    let isError = false;
    if (values.reference && values.reference.baseCount) {
      let refLimit = values.reference.baseCount;
      let addedRefSum = values.reference.config?.reduce((a, b) => a + b);
      if (addedRefSum !== refLimit) {
        isError = true;
      }
    }
    return isError;
  };
  const isValidForm = !isSubmitting && isEmpty(errors);

  return (
    <Modal
      size='lg'
      show={props?.show}
      onHide={props?.onHide}
      dialogClassName='customisePackageModal'
      backdropClassName={'customBGVModalBackdrop'}
    >
      <Modal.Header closeButton>
        <Modal.Title id='contained-modal-title-vcenter'>
          <h6 className={styles.mainTitle}>{`Customize ${pDetails?.subtypeName}`}</h6>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <Row>
            {values?.identity ? (
              <Col xl={12} md={12} lg={12} sm={12} xs={12} className={styles.checkContainer}>
                <Identity
                  pConfig={pDetails?.config?.identity}
                  sConfig={null}
                  values={values?.identity}
                  limit={values?.identity.baseCount}
                  isAadhaarAllowed={pDetails.isAadhaarAllowed}
                  handleChange={(name, value) => {
                    handleConfigChange('identity', name, value);
                  }}
                />
              </Col>
            ) : null}
            {values?.address ? (
              <Col xl={12} md={12} lg={12} sm={12} xs={12} className={styles.checkContainer}>
                <Address
                  pConfig={pDetails?.config?.address}
                  isCourt={false}
                  data-testid='court-input'
                  values={values.address}
                  handleChange={(name, value) => {
                    handleConfigChange('address', name, value);
                  }}
                />
              </Col>
            ) : null}
            {values?.court ? (
              <Col xl={12} md={12} lg={12} sm={12} xs={12} className={styles.checkContainer}>
                <Address
                  pConfig={pDetails?.config?.court}
                  isCourt={true}
                  values={values.court}
                  handleChange={(name, value) => {
                    handleConfigChange('court', name, value);
                  }}
                  data-testid='court-input'
                />
              </Col>
            ) : null}
            {pDetails?.config?.referenceType !== 'SPECIFIC_LOCK' &&
            values.reference &&
            values.reference.baseCount ? (
              <Col
                data-testid='reference-input'
                xl={12}
                md={12}
                lg={12}
                sm={12}
                xs={12}
                className={styles.checkContainer}
              >
                <Reference
                  pConfig={pDetails?.config?.reference}
                  empCount={pDetails?.config?.employment}
                  limit={values.reference.baseCount}
                  values={values.reference}
                  handleChange={(name, value) => {
                    handleConfigChange('reference', name, value);
                  }}
                  data-testid='reference-input'
                />
              </Col>
            ) : null}
          </Row>
        </Container>
      </Modal.Body>
      <Modal.Footer className={styles.modalFooter}>
        <Button
          className={styles.cancelBtn}
          onClick={() => {
            props.onHide();
          }}
          data-testid='close-button'
        >
          Cancel
        </Button>
        <Button
          disabled={!isValidForm || isRefError()}
          className={!isValidForm || isRefError() ? styles.disabledBtn : styles.activeBtn}
          onClick={handleSubmit}
        >
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
