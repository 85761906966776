import React, { useState, useEffect } from 'react';

import { Col, Container, Row, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { isCustomisable } from '../../../../utils/utilities';

import Package from './Package';
import Customize from './Customize';
import Image from '../../../../core-components/Image';
import { jwtParser } from '../../../../utils/utilities';
import localStorage from '../../../../api/localStorage';
import styles from './PackageListing.module.scss';
import _ from 'lodash';

export default (props) => {
  const [openCustomise, setOpenCustomise] = useState(false);
  const [packages, setPackages] = useState([]);
  const {
      packageList,
      handleStepSubmit,
      savedValues,
      createCandConfig,
      isBulkUpload,
      bulkCandidates,
      selectedPackageId,
      selectedPackage,
      handleSelectedSubtypeId
    } = props,
    isAdmin = localStorage.accessLevel === 'admin';

  const token = localStorage.getToken();
  const parsedJwtDetail = jwtParser(token);
  const packageType = parsedJwtDetail?.data?.modelType === 'PREPAID' ? 'PREPAID' : 'POSTPAID';
  const handleChoose = (subtypeId, isCustomizable = false) => {
    if (isCustomizable) {
      if (selectedPackageId !== subtypeId) {
        handleStepSubmit(['step2'], [null], null);
      }
      handleSelectedSubtypeId(subtypeId);
      setOpenCustomise(true);
    } else {
      handleSelectedSubtypeId(subtypeId);
      handleSelectPackage(null, false);
    }
  };

  useEffect(() => {
    if (packageType === 'PREPAID') {
      const candidateCount = isBulkUpload ? bulkCandidates : 1;
      const sufficient_packages = packageList.filter(
        (pckg) =>
          pckg.currentBalance - candidateCount > Math.floor(pckg.leastRechargeUnitsTier * 0.2)
      );
      const lowbalance_packages = packageList.filter(
        (pckg) =>
          pckg.currentBalance >= candidateCount &&
          pckg.currentBalance - candidateCount <= Math.floor(pckg.leastRechargeUnitsTier * 0.2)
      );
      const insufficient_packages = packageList.filter(
        (pckg) => pckg.currentBalance === 0 || pckg.currentBalance < candidateCount
      );
      setPackages([
        ..._.orderBy(sufficient_packages, ['currentBalance'], 'desc'),
        ..._.orderBy(lowbalance_packages, ['currentBalance'], 'desc'),
        ..._.orderBy(insufficient_packages, ['currentBalance'], 'desc')
      ]);
    } else {
      setPackages(packageList);
    }
  }, [packageList]);

  const handleSelectPackage = (values, isCustom = false) => {
    if (isCustom) {
      handleStepSubmit(['step2'], [values], 3);
    } else {
      props?.handleStepChange(3, true);
    }
  };

  const NoPackageFound = () => {
    return (
      <div className={styles.noPackageFound}>
        <Image name={'NoPackageFound.svg'} svg={'NoPackageFound.svg'} />
        {isAdmin ? (
          <div className={styles.textContainer}>
            <h2>{'Buy verification packages to verify candidates'}</h2>
            <p>
              To add and verify candidates, you need to buy verification packages
              <br />
              containing background checks.'
            </p>
            <Button variant={'primary'}>
              <Link to='/packages'>{'Buy packages'}</Link>
            </Button>
          </div>
        ) : (
          <div className={styles.textContainer}>
            <h2>{'There are no packages currently assigned to your account.'}</h2>
            <p>
              To add and verify candidates, please contact{' '}
              <a href='mailto:cs@springverify.com' target='_blank'>
                support team
              </a>
              <br />
              to buy verification packages containing background checks.
            </p>
            <Link to='/candidates'>{'Back to candidates'}</Link>
          </div>
        )}
      </div>
    );
  };

  const onClickHander = () => {
    if (isBulkUpload) {
      // eslint-disable-next-line no-unused-expressions
      // props?.history.push({
      //   pathname: '/bulkCandidate',
      //   state: { isBulkUpload: true, step: 2 }
      // });
      props?.goBackToBulkUpload();
    } else {
      // eslint-disable-next-line no-unused-expressions
      props?.handleStepChange(1);
    }
  };
  return (
    <>
      <Container style={{ minHeight: 'inherit' }}>
        {packages && packages.length ? (
          <div className={styles.baseGrid}>
            <div
              className={`${styles.packageListContainer} ${
                props.isBulkUpload ? styles.bulkUploadPackageListing : ''
              }`}
            >
              {packages.map((packg, index) => {
                return (
                  <Package
                    key={index}
                    index={index}
                    isAadhaarAllowed={packg.isAadhaarAllowed}
                    pDetails={{ ...packg, isCustomisable: isCustomisable(packg.config) }}
                    showBorder={selectedPackageId === packg.subtypeId}
                    handleChoose={handleChoose}
                    isStep3={false}
                    packageType={packageType}
                    bulkCandidates={bulkCandidates}
                  />
                );
              })}
              <Button
                variant='outline-primary'
                onClick={onClickHander}
                className={styles.goBackBtn}
                onMouseOver={(e) => {
                  e.target.style.color = '#ffffff';
                  e.target.style.backgroundColor = '#1f44ca';
                }}
                onMouseOut={(e) => {
                  e.target.style.color = '#2755fe';
                  e.target.style.backgroundColor = 'transparent';
                }}
              >
                {'Go back'}
              </Button>
              <br />
            </div>
          </div>
        ) : (
          <NoPackageFound />
        )}
      </Container>

      {openCustomise ? (
        <Customize
          show={openCustomise}
          pDetails={selectedPackage || null}
          onHide={() => {
            setOpenCustomise(false);
          }}
          savedValues={savedValues}
          onSubmit={(values) => {
            handleSelectPackage(values, true);
            setOpenCustomise(false);
          }}
        />
      ) : null}
    </>
  );
};
