import { isEmpty } from 'lodash';
import moment from 'moment/moment';
import React, { useState, useEffect } from 'react';
import { Col, Form, Table } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { Link } from 'react-router-dom';

import Pagination from '../../../../../core-components/Pagination';
import { USAGE_EVENTS } from '../../../../../utils/commonConstant';
import { convertToIndianNumeration, getDescription } from '../../../../../utils/utilities';
import EmptyTableBody from './EmptyTableBody';

import styles from './UsageLogsTable.module.scss';
import { capitalizeName } from '../../../frontend-common/utils/Utilities';

function UsageLogsTable(props) {
  const pageSizes = [10, 25, 50, 100];

  const [pageSize, setPageSize] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [count, setCount] = useState(0);
  const [data, setData] = useState([]);
  const [isData, setIsData] = useState(true);
  const [loading, setLoading] = useState(true);
  const { getUsageLogs, invalidateUsageLogs, usageLogs, companyId } = props;

  useEffect(() => {
    /** API call on mount */
    getUsageLogs(companyId);
    return () => {
      invalidateUsageLogs();
    };
  }, []);

  const paginate = (limitValue, offsetValue) => {
    /** Usage logs api call */
    getUsageLogs(companyId, limitValue, offsetValue);
  };

  useEffect(() => {
    if (!isEmpty(usageLogs)) {
      setLoading(usageLogs.loading);
      if (!usageLogs.loading) {
        setData(usageLogs.usageLogs.rows);
        setCount(usageLogs.usageLogs.count);
        setIsData(usageLogs.usageLogs.count > 0 ? true : false);
      }
    }
  }, [usageLogs]);

  const pageSizeOptions = [10, 25, 50, 100];
  let index = 0;
  do {
    const value = pageSizes[index] > count ? count : pageSizes[index];
    if (!value) break;
    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < count);

  const getCandidateName = (candidate) => {
    const name = capitalizeName(candidate?.name) || '-';
    const id = candidate?.id;
    return id ? (
      candidate?.deletedAt ? (
        <p className={styles.deletedData}>
          {name} ({id})
        </p>
      ) : (
        <p>
          <Link
            to={`viewCandidate?candidateId=${id}`}
            target='_blank'
            className={styles.descriptionData}
          >
            {name} ({id})
          </Link>
        </p>
      )
    ) : (
      name
    );
  };

  const getUser = (data) => {
    switch (data?.event) {
      case 'ADDITIONAL_CHARGE':
        return data?.user?.companyUserMapping
          ? data?.user?.name
          : data?.paymentsCompanyAdditionalCharge?.caApproveOrRejectedBy
          ? `${data?.paymentsCompanyAdditionalCharge?.caApproveOrRejectedBy?.name} (via SV)`
          : `Auto-approved`;
      case 'RECHARGE':
      case 'CREDITS_UNLOCKED':
        return data?.user
          ? data?.user?.companyUserMapping
            ? data?.user?.name
            : 'SpringVerify'
          : 'External User';
      default:
        const name = data?.user_type === 5 ? data?.companyApiToken?.name : data?.user?.name || '-';
        const companyUserMapping = data?.user?.companyUserMapping;
        return data?.user_type === 5
          ? `API ${name}`
          : companyUserMapping !== null
          ? `${name}`
          : `SpringVerify`;
    }
  };

  const getAmount = (value) => {
    let amount;
    let isNegative = false;

    if (value < 0) {
      amount = value * -1;
      isNegative = true;
    } else {
      amount = value;
    }

    return (
      <p className={styles.amount}>
        {`${isNegative ? '-' : ''}`}&#8377;{`${convertToIndianNumeration(amount)}`}
      </p>
    );
  };

  const getStyleForDeletedUser = (data) => {
    const isUserCa = data?.user?.companyUserMapping ? true : false;
    const isDeleted = data?.user?.deletedAt ? true : false;

    const isUserCaAndDeleted = isUserCa && isDeleted;

    return isUserCaAndDeleted ? styles.deletedUser : null;
  };

  return (
    <div className={styles.usageLogsWrapper}>
      <div className={styles.usageLogsCard}>
        <div className={styles.tableCard}>
          <div className={styles.tabInfoText}>Complete transaction and usage history.</div>
          <Table className={styles.usageLogsTable}>
            <thead>
              <tr>
                <th>Date</th>
                <th>TXN Type</th>
                <th>Description</th>
                <th>Candidate Name</th>
                <th>User</th>
                <th className={styles.alignRight}>Amount</th>
                <th className={styles.alignRight}>Available Balance</th>
              </tr>
            </thead>
            {isData ? (
              <tbody>
                {loading
                  ? Array.apply(null, { length: pageSize })
                      .map(Number.call, Number)
                      .map((loadData, i) => {
                        return (
                          <tr key={i}>
                            <td>
                              <ContentLoader height={20} width={20}>
                                <rect x='0' y='0' width='20' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                            <td>
                              <ContentLoader height={20} width={80}>
                                <rect x='0' y='0' width='80' height='14' />
                              </ContentLoader>
                            </td>
                          </tr>
                        );
                      })
                  : data &&
                    data.map((rowData, i) => {
                      return (
                        <tr key={i} className={styles.pointerTypeCursor}>
                          <td>{moment(rowData?.createdAt, 'YYYY-MM-DD').format('DD MMM YYYY')}</td>
                          <td>{USAGE_EVENTS[rowData?.event]}</td>
                          <td>{getDescription(rowData)}</td>
                          <td className={styles.mainField}>
                            {getCandidateName(rowData?.candidate)}
                          </td>
                          <td className={getStyleForDeletedUser(rowData)}>{getUser(rowData)}</td>
                          <td className={styles.alignRight}>
                            <span className={styles.amount}>{getAmount(rowData?.amount)}</span>
                          </td>
                          <td className={styles.alignRight}>
                            <span className={styles.amount}>
                              {getAmount(rowData?.availableBalance)}
                            </span>
                          </td>
                        </tr>
                      );
                    })}
              </tbody>
            ) : (
              <EmptyTableBody />
            )}
          </Table>
          {isData ? (
            <Form.Row className={styles.paginationWrapper}>
              <Col className={styles.showPagination}>
                Show{' '}
                <select
                  className={styles.selectPagination}
                  value={pageSize}
                  onChange={(event) => {
                    setPageNumber(0);
                    setPageSize(parseInt(event.target.value));
                    paginate(event.target.value, 0);
                  }}
                >
                  {pageSizeOptions}
                </select>{' '}
                items of {count}
              </Col>
              <Col>
                <Pagination
                  forcePage={pageNumber}
                  onPageChange={({ selected }) => {
                    setPageNumber(selected);

                    paginate(pageSize, selected);
                  }}
                  pageCount={Math.ceil(count / pageSize)}
                />
              </Col>
              <Col />
            </Form.Row>
          ) : (
            <div />
          )}
        </div>
      </div>
    </div>
  );
}

export default UsageLogsTable;
