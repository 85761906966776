import React, { useEffect, useState } from 'react';
// Component import
import { Col, Form, Table } from 'react-bootstrap';

// Styles import
import styles from './LogsTable.module.scss';
import ContentLoader from 'react-content-loader';
import Pagination from '../../../../../core-components/Pagination';
import moment from 'moment';
import { capitalize } from 'lodash';
import { convertToTitleCase } from '../../../../../utils/utilities';

const LogsTable = ({ addressId, davLogs, getDavLogs, invalidateDavLogs }) => {
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(10);
  const [pageNumber, setPageNumber] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [expandedRows, setExpandedRows] = useState([]);
  const pageSizeOptions = [];
  const pageSizes = [10, 25, 50, 100];
  let index = 0;
  useEffect(() => {
    getDavLogs(addressId, pageSize, offset);
    return () => {
      invalidateDavLogs();
    };
  }, [limit, offset]);
  const paginate = (pageNumber, offset) => {
    setPageNumber(pageNumber);
    setOffset(offset);
  };
  const handleRowExpand = (index) => {
    const updatedExpandedRows = JSON.parse(JSON.stringify(expandedRows));
    expandedRows.includes(index)
      ? updatedExpandedRows.splice(updatedExpandedRows.indexOf(index), 1)
      : updatedExpandedRows.push(index);
    setExpandedRows(updatedExpandedRows);
  };
  const getActivityData = (log, index) => {
    let activityLog = convertToTitleCase(capitalize(log?.event));

    if (log.event.includes('IMAGE')) {
      activityLog = `${activityLog} for - ${log.logsText}`;
    } else if (log.event.includes('QUESTION')) {
      activityLog = `Response provided for '${log.logsText}'`;
    }

    // Show SpringVerify for System/SA users and HR: for HR user
    const logUser =
      log.user?.userType === 4 || log.user?.userRoleId != null
        ? 'SpringVerify'
        : `HR:${log.user?.name}(${log.user?.id})`;
    if (log.event?.includes('FORM_INITIATE')) {
      activityLog = `${activityLog} by ${logUser}`;
    } else if (log.event?.includes('FORM_REINITIATE')) {
      activityLog = `DAV form reinitiated for ${log.details} by ${logUser}`;
    } else if (log.event.includes('CRON_REMINDER_SENT')) {
      if (log.metadata?.includes('EMAIL')) {
        activityLog = `Reminder Email sent to primary email (${log?.logsText})`;
      } else if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `Reminder WhatsApp sent to primary number (${log?.logsText})`;
      }
    } else if (log.event.includes('CRON_REMINDER_READ')) {
      if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `Reminder WhatsApp read`;
      } else if (log.metadata?.includes('EMAIL')) {
        activityLog = `Reminder Email read`;
      }
    } else if (log.event.includes('CRON_REMINDER_DELIVERED')) {
      if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `Reminder WhatsApp delivered`;
      } else if (log.metadata?.includes('EMAIL')) {
        activityLog = `Reminder Email delivered`;
      }
    } else if (log.event.includes('REMINDER_SENT')) {
      if (log.metadata?.includes('EMAIL')) {
        activityLog = `Email sent to primary email (${log?.logsText})`;
      } else if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `WhatsApp sent to primary number (${log?.logsText})`;
      }
    } else if (log.event.includes('REMINDER_READ')) {
      if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `WhatsApp notification read`;
      } else if (log.metadata?.includes('EMAIL')) {
        activityLog = `Email notification read`;
      }
    } else if (log.event.includes('REMINDER_DELIVERED')) {
      if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `WhatsApp notification delivered`;
      } else if (log.metadata?.includes('EMAIL')) {
        activityLog = `Email notification delivered`;
      }
    } else if (log.event.includes('CRON_REMINDER_BOUNCED')) {
      if (log.metadata?.includes('EMAIL')) {
        activityLog = `Reminder Email bounced (${log?.logsText})`;
      }
    } else if (log.event.includes('REMINDER_BOUNCED')) {
      if (log.metadata?.includes('EMAIL')) {
        activityLog = `Email bounced (${log?.logsText})`;
      }
    } else if (log.event.includes('CRON_REMINDER_FAILED')) {
      if (log.metadata?.includes('EMAIL')) {
        activityLog = `Reminder Email failed to send (${log?.logsText})`;
      } else if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `Reminder WhatsApp failed to deliver`;
      }
    } else if (log.event.includes('REMINDER_FAILED')) {
      if (log.metadata?.includes('EMAIL')) {
        activityLog = `Email Failed to send(${log?.logsText})`;
      } else if (log.metadata?.includes('WHATSAPP')) {
        activityLog = `WhatsApp notification failed to deliver`;
      }
    } else if (log.event.includes('FORM_OPEN')) {
      activityLog = `Form Opened(${log.instanceCount})`;
    } else {
      if (expandedRows.includes(index)) {
        activityLog = `${activityLog} @ ${capitalize(log.metadata || '')} ${log?.ipAddress || ''} ${
          log?.browserAgent ? log?.browserAgent : ''
        }`;
      } else {
        activityLog = (
          <span>
            {activityLog}{' '}
            <span className={styles.browserInformation} onClick={() => handleRowExpand(index)}>
              &lt;Browser Information&gt;
            </span>
          </span>
        );
      }
    }
    return activityLog;
  };
  const timeStamp = (time) => {
    return moment(time).format('DD MMM YYYY hh:mm A');
  };
  do {
    const value =
      pageSizes[index] > davLogs?.data?.data?.count ? davLogs?.data?.data?.count : pageSizes[index];

    if (!value) break;

    pageSizeOptions.push(
      <option key={index} value={value}>
        {value}
      </option>
    );
  } while (pageSizes[index++] < davLogs?.data?.data?.count);

  const getDetailsData = (log) => {
    if (log.event.includes('IMAGE') && log.answerSource) {
      return (
        <a className={styles.browserInformation} href={log.answerSource} target='_blank'>
          &lt;Image&gt;
        </a>
      );
    } else if (log.event.includes('QUESTION') && log.answerSource) {
      let answer = log.answerSource;
      if (log.logsText?.includes('Picture of name/number board of the house')) {
        if (typeof answer === 'number' || ['0', '1'].includes(answer)) {
          // Convert to number and check if 1
          answer = Number(answer) === 1 ? 'Selected' : 'Not Selected';
        }
      }
      return <span>{answer}</span>;
    } else {
      return <span>-</span>;
    }
  };

  return (
    <>
      <Table className={styles.logsTable}>
        <thead>
          <tr>
            <th>#</th>
            <th>Activity</th>
            <th>Details</th>
            <th>TimeStamp</th>
          </tr>
        </thead>
        <tbody>
          {davLogs?.loading
            ? Array.apply(null, { length: pageSize })
                .map(Number.call, Number)
                .map((_loadData, i) => {
                  return (
                    <tr key={i}>
                      <td className={styles.mainField}>
                        <ContentLoader height={20} width={100}>
                          <rect x='0' y='0' width='100' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={200}>
                          <rect x='0' y='0' width='200' height='14' />
                        </ContentLoader>
                      </td>
                      <td>
                        <ContentLoader height={20} width={100}>
                          <rect x='0' y='0' width='100' height='14' />
                        </ContentLoader>
                      </td>
                    </tr>
                  );
                })
            : !davLogs?.loading &&
              davLogs?.data?.data?.logs?.length > 0 &&
              davLogs?.data?.data?.logs?.map((rowData, i) => {
                return (
                  <tr key={i}>
                    <td>{i + 1 + limit * pageNumber}</td>
                    <td className={styles.mainField}>{getActivityData(rowData, i)}</td>
                    <td>{getDetailsData(rowData)}</td>
                    <td>{timeStamp(rowData.createdAt)}</td>
                  </tr>
                );
              })}
        </tbody>
      </Table>
      {davLogs?.data?.data?.count > 0 && (
        <Form.Row className={styles.paginationWrapper}>
          <Col className={styles.showPagination}>
            Show{' '}
            <select
              className={styles.selectPagination}
              value={limit}
              onChange={(event) => {
                setLimit(parseInt(event.target.value));
                setPageSize(parseInt(event.target.value));
                paginate(0, 0);
              }}
            >
              {pageSizeOptions}
            </select>
            items of {davLogs?.data?.data?.count}
          </Col>
          <Col>
            <Pagination
              forcePage={pageNumber}
              onPageChange={({ selected }) => {
                paginate(selected, selected);
              }}
              pageCount={Math.ceil(davLogs?.data?.data?.count / pageSize)}
            />
          </Col>
          <Col />
        </Form.Row>
      )}
    </>
  );
};

export default LogsTable;
